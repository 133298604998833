import './style.scss';
import React, { Component } from 'react';
import { Lister, slugify } from './listUtil.js';


class TodoWidget extends Component {
  constructor(props) {
    super(props);
    let listID = props.listID || 'default';
    this.state = {
      listID: listID,
      admin: Boolean(props.admin) || false,
      view: 'list',
      status: 'init'
    };
    this.lister = new Lister({
      storage: props.storage,
      authToken: props.authToken,
      onLoad: () => this.setState({status: 'loaded'})
    });
  }

  toggleView() {
    this.setState({
      view: this.state.admin ?
        (this.state.view === 'list' ? 'admin' : 'list') : 'list'
    });
  }

  async saveLists() {
    await this.lister.save();
    window.alert('Lists saved');
  }

  render() {
    if (this.state.status === 'init') {
      return (<div className="TodoWidget">Loading todos...</div>);
    }

    var title,
        content,
        displayView,
        saveButton = '',
        headerActions = '';

    // if admin view
    if (this.state.view === 'admin') {
      title = 'Todo lists admin ';
      content = <TodoAdmin listID={this.state.listID} lister={this.lister} />;
      displayView = 'View';
      saveButton = <button onClick= {() => this.saveLists()}>Save</button>;
    }

    // if basic todo list view
    else {
      let list = this.lister.getList(this.state.listID);
      title = this.props.title || (list && list.title) || 'Todo';
      content = <TodoList listID={this.state.listID} lister={this.lister} />;
      displayView = 'Admin';
    }

    // toggle for admin users
    if (this.state.admin) {
      headerActions = (
        <div className="todoHeaderActions">
          {saveButton}
          <button onClick={e => this.toggleView(e)}>
            {displayView}
          </button>
        </div>
      );
    }

    return (
      <div className="TodoWidget">
        <div className="todoHeader">
          <div className="todoTitle">
            {title}
          </div>
          {headerActions}
        </div>
        {content}
      </div>
    );
  }
}


class TodoList extends Component {
  constructor(props) {
    super(props);
    this.lister = props.lister;
    this.state = {
      listID: props.listID,
      state: props.lister.getListItemsState(props.listID)
    };
  }
  toggleDone(itemID) {
    this.lister.toggleListItemState(this.state.listID, itemID);
    this.setState({
      state: this.lister.getListItemsState(this.state.listID)
    });
  }
  render() {
    var items = this.lister.getListItems(this.state.listID) || [];
    var todoItems = [];
    for (let item of items) {
      todoItems.push(
        <TodoItem key={item.id} item={item} checked={this.state.state}
                  toggleDone={() => this.toggleDone(item.id)} />
      );
    }
    return (
      <div className="todoList">
        <ul className="todoItems">
          {todoItems}
        </ul>
      </div>
    );
  }
}


class TodoItem extends Component {
  render() {
    return (
      <li>
        <div className="item">
          <input
            type="checkbox"
            checked={
              ~this.props.checked.indexOf(this.props.item.id) ? 'checked' : ''
            }
            onChange={() => this.props.toggleDone()}/>
          {this.props.item.title}
        </div>
      </li>
    );
  }
}


class TodoAdmin extends Component {
  constructor(props) {
    super(props);
    this.lister = props.lister;
    let listID = this.checkListExists(props.listID);
    let initState = this.getList(listID);
    initState.context = 'view';
    initState.lister = this.lister.getLists();
    initState.editItem = null;
    this.state = initState;
  }

  checkListExists(listID) {
    let list = this.lister.getList(listID);
    if (list) {
      return listID;
    }
    let lists = this.lister.getLists();
    if (lists.length) {
      return lists[0].id;
    }
    list = this.lister.getList('default', true);
    return list.id;
  }

  getList(listID) {
    var list = this.lister.getList(listID);
    return {
      listID: listID,
      listTitle: list.title,
      listItems: list.items,
    };
  }
  loadList(listID) {
    let stateUpdate = { context: 'view' };
    Object.assign(stateUpdate, this.getList(listID));
    this.setState(stateUpdate);
  }

  /***
   * List operations.
   */
  listSelect(listID) {
    this.loadList(listID);
  }
  listAdd() {
    this.setState({ context: this.state.context === 'add' ? 'view' : 'add' });
  }
  listEdit() {
    this.setState({ context: this.state.context === 'edit' ? 'view' : 'edit' });
  }
  listDelete() {
    if (window.confirm(`Really delete: ${this.state.listTitle}?`)) {
      this.lister.deleteList(this.state.listID);
      this.loadList(this.lister.getLists()[0].id);
    };
  }
  listEditSubmit(listID, listTitle) {
    listID = slugify(listID);
    let currentIDs = this.state.lister.map(list => list.id);
    // for edit, allow current id (remove the current listID from IDs to check
    // for duiplicity)
    let currentIDIndex = currentIDs.indexOf(this.state.listID);
    if (~currentIDIndex && this.state.context === 'edit') {
      currentIDs.splice(currentIDIndex, 1);
    }

    // validate input
    if (!listID || !listTitle) {
      window.alert(`Enter ID and Title for the list.`);
    }
    else if (~currentIDs.indexOf(listID)) {
      window.alert(`The ID "${listID}" is already in use.`);
    }

    // process list
    else {
      if (this.state.context === 'edit') {
        this.lister.updateList(this.state.listID, listID, listTitle);
      }
      else if (this.state.context === 'add') {
        this.lister.addList(listID, listTitle);
      }
      this.loadList(listID);
    }
  }
  listEditCancel() {
    this.setState({ context: 'view' });
  }

  /***
   * Item operations.
   */
  reloadItems(editItem=null) {
    this.setState({
      listItems: this.lister.getListItems(this.state.listID),
      editItem: editItem,
    });
  }

  itemAdd() {
    let item = this.lister.addListItem(this.state.listID, 'New todo...');
    this.reloadItems(item.id);
  }
  itemEditToggle(itemID, itemTitle) {
    this.lister.updateListItem(this.state.listID, itemID, itemTitle);
    this.reloadItems(this.state.editItem === null ? itemID : null);
  }
  itemDelete(itemID) {
    this.lister.deleteListItem(this.state.listID, itemID);
    this.reloadItems();
  }
  itemMoveUp(itemID) {
    this.lister.moveListItemUp(this.state.listID, itemID);
    this.reloadItems();
  }
  itemMoveDown(itemID, event) {
    this.lister.moveListItemDown(this.state.listID, itemID);
    this.reloadItems();
  }

  render() {
    var todoItems = [];
    let count = 0;
    for (let item of this.state.listItems) {
      todoItems.push(
        <TodoAdminItem
          key={item.id} item={item} edit={item.id === this.state.editItem}
          itemOrder={++count}
          itemEditToggle={(itemTitle) => this.itemEditToggle(item.id, itemTitle)}
          itemDelete={() => this.itemDelete(item.id)}
          itemMoveUp={() => this.itemMoveUp(item.id)}
          itemMoveDown={() => this.itemMoveDown(item.id)}
        />
      );
    }
    return (
      <div className="todoAdmin">
        <TodoAdminListSelect
          selectedListID={this.state.listID}
          lister={this.lister}
          listSelect={(listID) => this.listSelect(listID)}
          listAdd={() => this.listAdd()}
          listEdit={() => this.listEdit()}
          listDelete={() => this.listDelete()}
        />
        <TodoAdminListEdit
          show={~['add', 'edit'].indexOf(this.state.context)}
          editSubmit={(id, title) => this.listEditSubmit(id, title)}
          editCancel={() => this.listEditCancel()}
          listID={this.state.listID}
          listTitle={this.state.listTitle}
        />
        <hr />
        <p><b>Items:</b></p>
        <ul className="todoItems">
          {todoItems}
        </ul>
        <div className="todoNew">
          <button onClick={() => this.itemAdd()}>+</button>
        </div>
      </div>
    );
  }
}


class TodoAdminListEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.listID, title: props.listTitle,
      propsListID: props.listID
    };
  }
  static getDerivedStateFromProps(props, state) {
    // another list selected
    if (state.propsListID !== props.listID) {
      return {
        id: props.listID,
        title: props.listTitle,
        propsListID: props.listID
      };
    }
    return {};
  }
  render() {
    if (!this.props.show) {
      return '';
    }
    return (
      <div className="listEdit">
        <table>
          <tbody>
            <tr>
              <th><label>ID:</label></th>
              <td>
                <input type="text" value={this.state.id}
                       onChange={(e) => this.setState({ id: e.target.value })}/>
              </td>
            </tr>
            <tr>
              <th><label>Title:</label></th>
              <td>
                <input type="text" value={this.state.title}
                       onChange={(e) => this.setState({ title: e.target.value })}/>
              </td>
            </tr>
            <tr>
              <td>
              </td>
              <td>
                <button onClick={() => this.props.editCancel()}>Cancel</button>
                <button onClick={() => this.props.editSubmit(this.state.id, this.state.title)}>OK</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}


class TodoAdminListSelect extends Component {
  constructor(props) {
    super(props);
    this.lister = props.lister;
  }
  render() {
    let lists = this.lister.getLists();
    let items = [];
    lists.forEach(list => {
      items.push(<option key={list.id} value={list.id}>{list.title}</option>);
    });
    return (
      <div className="listActions">
        <p><b>Lists:</b></p>
        <p>
          <select
            onChange={e => this.props.listSelect(e.target.value)}
            value={this.props.selectedListID}>
            {items}
          </select>
          <button title="Edit selected list"
                  onClick={() => this.props.listEdit()}>:edit</button>
          <button title="Delete selected list"
                  onClick={() => this.props.listDelete()}>-del</button>
          <button title="Create new list"
                  onClick={() => this.props.listAdd()}>+new</button>
        </p>
      </div>
    );
  }
}


class TodoAdminItem extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = { itemTitle: props.item.title };
  }
  keyPressed(event) {
    if (~[13, 27].indexOf(event.keyCode)) {
      this.props.itemEditToggle(event.target.value);
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (!props.edit) {
      return { itemTitle: props.item.title };
    }
    return {};
  }
  componentDidUpdate() {
    this.textInput.current && this.textInput.current.focus();
  }
  componentDidMount() {
    this.textInput.current && this.textInput.current.focus();
  }

  render() {
    let valueRender, editLabel;
    if (this.props.edit) {
      valueRender = (
        <input
          type="text" value={this.state.itemTitle}
          placeholder="What to do..."
          ref={this.textInput}
          onChange={e => this.setState({ itemTitle: e.target.value })}
          onKeyUp={e => this.keyPressed(e)} />
      );
      editLabel = <>&#10003;</>;
    } else {
      valueRender = (
        <span
          className="title"
          onClick={() => this.props.itemEditToggle(this.state.itemTitle)}>
          {this.state.itemTitle}
        </span>
      );
      editLabel = <>&#120087;</>;
    }
    return (
      <li>
        <div className="item">
          <div className="itemOrder">{this.props.itemOrder}.</div>
          {valueRender}
        </div>
        <div className="action">
          <button onClick={() => this.props.itemEditToggle(this.state.itemTitle)}>
            {editLabel}
          </button>
          <button onClick={() => this.props.itemMoveUp()}>
            &uarr;
          </button>
          <button onClick={() => this.props.itemMoveDown()}>
            &darr;
          </button>
          <button onClick={(e) => {
            if (window.confirm(`Delete "${this.props.item.title}"?`)) {
              this.props.itemDelete();
            }}}>
            &#10007;
          </button>
        </div>
      </li>
    );
  }
}


export default TodoWidget;
