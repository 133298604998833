import React from 'react';
import ReactDOM from 'react-dom';
import TodoWidget from './TodoWidget';
import * as serviceWorker from './serviceWorker';

const DATA_ATTRS = ['title', 'listID', 'admin', 'storage', 'authToken'];

function getData(element) {
  var data = {};
  for (let prop of DATA_ATTRS) {
    let attr = 'data-' + prop;
    data[prop] = element.attributes[attr] !== undefined ?
      element.attributes[attr].value : null;
  }
  return data;
}

let appElements = document.querySelectorAll('.todo-widget');
for (let appEl of appElements) {
  let data = getData(appEl);
  ReactDOM.render(
    <TodoWidget
      title={data.title}
      listID={data.listID}
      admin={data.admin}
      storage={data.storage}
      authToken={data.authToken}
    />,
    appEl);
}

serviceWorker.unregister();
