/***
 * TODO list utilities.
 *
 * Loading, saving, etc.
 */
const LS_TODO_LISTS = 'misters-todo-widget-lists';
const LS_TODO_STATES = 'misters-todo-widget-states';
const WP_API_URL_LOADLISTS = '/wp-json/todolist/load-lists/';
const WP_API_URL_SAVELISTS = '/wp-json/todolist/save-lists/';
const WP_API_URL_LOADSTATES = '/wp-json/todolist/load-states/';
const WP_API_URL_SAVESTATES = '/wp-json/todolist/save-states/';


const STORAGE_BACKENDS = ['local', 'wp'];


export class Lister {
  constructor(options) {
    options = options || {};
    this.storage = (options.storage || 'local').toLowerCase();
    this.onLoad = options.onLoad || (() => {});
    if (!~STORAGE_BACKENDS.indexOf(this.storage)) {
      this.storage = 'local';
    }
    this.authToken = options.authToken || null;
    this.init();
  }

  async init() {
    this.lists = await this.loadLists();
    this.states = await this.loadStates();
    this.onLoad();
  }

  /***
   * List methods.
   */
  getLists() {
    let lists = [];
    for (let listID in this.lists) {
      let list = this.lists[listID];
      lists.push({ id: listID, title: list.title });
    }
    return lists.sort((a, b) => { return a.title < b.title ? -1 : 1; });;
  }

  getList(listID='default', create=false) {
    let list = this.lists[listID];
    if (!list && create) {
      list = this.addList(listID, 'Todo');
    }
    return list;
  }

  listExists(listID) {
    return Boolean(this.getList(listID));
  }

  addList(listID, title) {
    let list = { title: title, items: [] };
    if (!this.lists[listID]) {
      this.lists[listID] = list;
    }
    return list;
  }

  updateList(listID, newListID, title) {
    let list = this.lists[listID];
    if (list) {
      list.title = title;
      if (listID !== newListID) {
        this.lists[newListID] = this.lists[listID];
        delete this.lists[listID];
      }
    }
  }

  deleteList(listID) {
    delete this.lists[listID];
  }

  /***
   * Todo items methods.
   */

  getListItems(listID) {
    let list = this.getList(listID);
    return list && list.items;
  }

  listItemExists(listID, itemID) {
    for (let item of this.getListItems(listID) || []) {
      if (item.id === itemID) {
        return true;
      }
    }
    return false;
  }

  setListItems(listID, items=null) {
    if (this.lists[listID]) {
      this.lists[listID].items = items;
    }
  }

  addListItem(listID, itemTitle='') {
    let items = this.getListItems(listID);
    if (!itemTitle) {
      return items;
    }
    let nextID = 0;
    for (let item of items) {
      if (item.id >= nextID) {
        nextID = item.id + 1;
      }
    }
    let item = { id: nextID, title: itemTitle };
    items.push(item);
    this.setListItems(listID, items);
    return item;
  }

  updateListItem(listID, itemID, itemTitle) {
    for (let item of this.getListItems(listID)) {
      if (item.id === itemID) {
        item.title = itemTitle;
        break;
      }
    }
  }

  deleteListItem(listID, itemID) {
    let deleted;
    let items = [];
    for (let item of this.getListItems(listID)) {
      if (item.id !== itemID) {
        items.push(item);
      } else {
        deleted = item;
      }
    }
    this.setListItems(listID, items);
    return deleted;
  }

  moveListItemUp(listID, itemID) {
    let items = this.getListItems(listID);
    let previous;
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.id === itemID) {
        if (previous) {
          items[i - 1] = item;
          items[i] = previous;
        }
        break;
      }
      previous = item;
    }
  }

  moveListItemDown(listID, itemID) {
    let items = this.getListItems(listID);
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let nextItem = items[i + 1];
      if (item.id === itemID) {
        if (nextItem) {
          items[i + 1] = item;
          items[i] = nextItem;
        }
        break;
      }
    }
  }

  /***
   * State methods.
   */

  getListItemsState(listID) {
    return this.states[listID] || [];
  }
  toggleListItemState(listID, itemID) {
    if (!this.listExists(listID) ||
        !this.listItemExists(listID, itemID)) {
      return;
    }
    let state = this.getListItemsState(listID);
    if (~state.indexOf(itemID)) {
      state.splice(state.indexOf(itemID), 1);
    } else {
      state.push(itemID);
    }
    this.states[listID] = state;
    this.saveState(listID);
  }


  /***
   * Save methods.
   */
  async save() {
    this.saveLists(this.lists);
  }
  async saveState(listID) {
    this.saveStates(this.states, listID);
  }

  /**
   * Load items lists.
   */
  async loadLists() {
    var lists = {};
    var listsJson = '';

    // WordPress
    if (this.storage === 'wp' && this.authToken) {
      let url = `${WP_API_URL_LOADLISTS}?_wpnonce=${this.authToken}`;
      let response = await window.fetch(url, { credentials: 'same-origin' });
      response = await response.json();
      listsJson = response && response.lists;
    }

    // localStorage
    else {
      listsJson = window.localStorage.getItem(LS_TODO_LISTS);
    }

    try {
      lists = JSON.parse(listsJson);
    } catch (e) {
      console.error('Error getting todo lists.', e);
    }

    return cleanLists(lists);
  }

  /**
   * Save items lists.
   */
  async saveLists(lists) {
    lists = JSON.stringify(cleanLists(lists || {}));

    // WordPress
    if (this.storage === 'wp' && this.authToken) {
      let url = `${WP_API_URL_SAVELISTS}?_wpnonce=${this.authToken}`;
      let data = new FormData();
      data.append('lists', lists);
      let params = { credentials: 'same-origin', body: data, method: 'POST' };
      await window.fetch(url, params);
    }

    // localStorage
    else {
      window.localStorage.setItem(LS_TODO_LISTS, lists);
    }
  }

  /**
   * Load states list.
   */
  async loadStates() {
    var states = {};
    var statesJson = '';

    // WordPress
    if (this.storage === 'wp' && this.authToken) {
      let url = `${WP_API_URL_LOADSTATES}?_wpnonce=${this.authToken}`;
      let response = await window.fetch(url, { credentials: 'same-origin' });
      response = await response.json();
      statesJson = response && response.states;
    }

    // localStorage
    else {
      statesJson = window.localStorage.getItem(LS_TODO_STATES);
    }

    try {
      states = JSON.parse(statesJson);
    } catch (e) {
      console.error('Error getting todo states.', e);
    }
    return cleanLists(states, 'states');
  }

  /**
   * Save states lists.
   */
  async saveStates(states, listID) {
    states = cleanLists(states, 'states');
    // WordPress
    if (this.storage === 'wp' && this.authToken) {
      let savedStates;
      if (listID) {
        savedStates = await this.loadStates();
        // update this list only to prevent rewriting other list states when
        // widget used for multiple lists on a page
        if (Object.keys(savedStates).length) {
          savedStates[listID] = states[listID];
          states = savedStates;
        }
      }
      let url = `${WP_API_URL_SAVESTATES}?_wpnonce=${this.authToken}`;
      let data = new FormData();
      data.append('states', JSON.stringify(states));
      let params = { credentials: 'same-origin', body: data, method: 'POST' };
      await window.fetch(url, params);
    }

    // localStorage
    else {
      window.localStorage.setItem(LS_TODO_STATES, JSON.stringify(states));
    }
  }
}


export function slugify(text) {
  var slug = '';
  var trimmed = text.trim();
  slug = trimmed
    .replace(/[^a-z0-9-]/gi, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');
  return slug.toLowerCase();
}


/***
 * Internal functions.
 */


/**
 * Ensure the list have proper structure.
 */
function cleanLists(dirty, type='items') {
  dirty = dirty || {};
  var lists = {};
  for (let attr in dirty) {
    let list = dirty[attr];
    if (typeof attr == 'string' && list && typeof list === 'object') {
      lists[attr] = list;
    }
  }

  if (type === 'items') {
    for (let attr in lists) {
      let list = lists[attr];
      list.items = cleanItems(list.items);
    }
    if (!Object.keys(lists).length) {
      lists.default = {title: 'Default TODO', items: []};
    }
  }

  else if (type === 'states') {
    for (let attr in lists) {
      if (!Array.isArray(lists[attr])) {
        delete lists[attr];
      }
    }
  }
  return lists;
}

/**
 * Endure the list items have proper structure.
 */
function cleanItems(dirty) {
  dirty = Array.isArray(dirty) ? dirty : [];
  let nextID = 0;
  let items = [];
  let ids = [];

  // get the last ID
  for (let item of dirty) {
    if (!item.title) {
      continue;
    }
    let id = parseInt(item.id);

    if (isNaN(id)) {
      item.id = null;
    }

    else {
      item.id = id;
      if (nextID <= id) {
        nextID = id + 1;
      }
      if (!~ids.indexOf(id)) {
        ids.push(id);
      } else {
        item.id = null;
      }
    }
    items.push(item);
  }
  //  assign id to all items without one
  for (let item of items) {
    if (!item.id) {
      item.id = ++nextID;
    }
  }
  return items;
}
